import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PdfViewer from '../pdfViewer/PdfViewer';

const styles = (theme) => ({
	table: {
		borderCollapse: 'collapse',
		margin: '0 auto',
		textAlign: 'center',
		overflow: 'auto'
	}
});

const TermsConditionPrivate = ({ classes }) => {
	return (
		<div data-testid="TermsConditionPrivate">
			<PdfViewer url="https://asistensi-content-delivery.s3.amazonaws.com/RD/documentos/RD+-+TCs+-+Plan.pdf" />
		</div>
	);
};

TermsConditionPrivate.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TermsConditionPrivate);
