import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from '@material-ui/core';
import TermsDisplayer from './TermsDisplayer';

const logo = '/static/images/Asistensi_logotipo.svg';

const TermsConditionDialog = ({
	fullScreen,
	open = false,
	handleClose = null,
	isAllyMaster = false,
	isAllyBasic = false,
	isACUNSA = false,
	is_affiliation = false,
	isPdf = false
}) => {
	return (
		<Dialog
			open={open}
			maxWidth="md"
			fullWidth={true}
			onClose={handleClose}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
			fullScreen={fullScreen}
		>
			<DialogTitle className="text-gray-900 font-bold">
				<img loading="lazy" src={logo} alt="asistensi_logotipo" width="160px" />
			</DialogTitle>

			<DialogContent>
				<TermsDisplayer
					isACUNSA={isACUNSA}
					isAllyBasic={isAllyBasic}
					isAllyMaster={isAllyMaster}
					handleClose={handleClose}
					isPdf={isPdf}
					is_affiliation={is_affiliation}
				/>
			</DialogContent>

			{!is_affiliation && (
				<DialogActions>
					{!isPdf && (
						<button className="secondary-button-nav" onClick={handleClose}>
							Cerrar
						</button>
					)}

					{isPdf && (
						<div className="flex justify-evenly my-10 w-full">
							<button
								className="secondary-button-nav mr-2"
								onClick={() => handleClose(false, 'acceptTerms')}
							>
								No acepto
							</button>
							<button
								className="white-button-nav"
								onClick={() => handleClose(true, 'acceptTerms')}
							>
								Acepto
							</button>
						</div>
					)}
				</DialogActions>
			)}
		</Dialog>
	);
};

TermsConditionDialog.propTypes = {
	fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog({ breakpoint: 'xs' })(TermsConditionDialog);
