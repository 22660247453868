import React, { Component } from 'react';
import PdfViewer from '../pdfViewer/PdfViewer';

class TermsConditionAcunsa extends Component {
	render() {
		return (
			<div data-testid="TermsConditionAcunsa" className="max-w-5xl mx-auto w-full">
				<PdfViewer url="https://asistensi-content-delivery.s3.amazonaws.com/RD/documentos/RD+-+Acunsa+-+Po%CC%81liza+CCGG+.pdf" />
			</div>
		);
	}
}

export default TermsConditionAcunsa
