import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from '@material-ui/core';
import PrivacyPolicySOSPage from '../../../pages/privacypolicy/privacy_sos';
import MemberTerms from './MemberTerms';
import SOSTermsCondition from './SOSTerms';

const logo = '/static/images/Asistensi_logotipo.svg';

class PrivacyPolicyDialog extends Component {
	render() {
		const { fullScreen, open, handleClose, isSignUp } = this.props;

		return (
			<Dialog
				open={open}
				maxWidth="md"
				onClose={handleClose}
				disableBackdropClick={true}
				disableEscapeKeyDown={true}
				fullScreen={fullScreen}
			>
				<DialogTitle className="text-gray-800 font-bold">
					<img src={logo} alt="Asistensi_logotipo" className="h-12 w-auto" />
				</DialogTitle>
				<DialogContent>
					{isSignUp && (
						<>
							<MemberTerms />
							<SOSTermsCondition ocult={true} />
						</>
					)}
					<PrivacyPolicySOSPage isDialog={true} ocult={true} />
				</DialogContent>
				<DialogActions>
					<button className="secondary-button-nav" onClick={handleClose}>
						Cerrar
					</button>
				</DialogActions>
			</Dialog>
		);
	}
}

PrivacyPolicyDialog.propTypes = {
	fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog({ breakpoint: 'xs' })(PrivacyPolicyDialog);
