import React from 'react';

const MemberTerms = () => {
	return (
		<div className="w-full">
			<h3 className="font-bold text-center">TÉRMINOS Y CONDICIONES</h3>
			<h3 className="font-bold text-center">
				MEMBRESÍA ASISTENSI Y ASISTENSI SOS
			</h3>
			<p className="my-4">
				<b>MEMBRESÍA ASISTENSI</b>
			</p>
			<p className="my-4">
				En cumplimiento de la normativa de Comercio Electrónico, Computer Misuse
				Act y Transactions Act CAP 308B y demás regulaciones asociadas y en
				cumplimiento con la normativa de protección de datos 2019 Data
				Protection Act, se facilitan los siguientes datos identificativos del
				titular del Sitio Web:
			</p>
			<p className="my-4">
				Asistensi Global Insurance Inc., con domicilio en Level 3, Cedar Court,
				Wildey Business Park, Wildey, St. Michael, Barbados BB14006., inscrito
				en el Registro de Barbados bajo el número 44330 y número TIN
				1000222885424 (en adelante "ASISTENSI").
			</p>
			<p className="my-4">
				La dirección de correo electrónico de contacto en relación con este
				sitio web, es la siguiente:{' '}
				<a href="mailto:info@asistensi.com" className="font-bold">
					info@asistensi.com
				</a>
			</p>
			<p className="my-4">
				La dirección de correo electrónico para cuestiones de privacidad:{' '}
				<a href="mailto:dpo@asistensi.com" className="font-bold">
					dpo@asistensi.com
				</a>{' '}
				en su calidad de Encargado de datos, respeta los derechos de privacidad
				y reconoce la importancia de proteger la Información Personal.
			</p>

			<div className="font-bold">DEFINICIONES:</div>

			<p className="my-4">
				<b>COLECTIVO:</b> Conjunto de personas físicas que forman parte de la
				Membresía Asistensi.
			</p>
			<p className="my-4">
				<b>MIEMBRO:</b> Toda persona física que decide asociarse o formar parte
				del Colectivo de Asistensi acorde a los requisitos y condiciones del
				mismo.
			</p>
			<p className="my-4">
				<b>FAMILIARES:</b> Toda persona física que sea relativo a un Miembro del
				Colectivo.
			</p>
			<p className="my-4">
				<b>MEMBRESÍA ASISTENSI:</b> Es la condición de Miembro de una comunidad
				de usuarios registrados en la página web de Asistensi para tener acceso
				a diversos beneficios y contratación de prestación de servicios.
			</p>
			<p className="my-4">
				<b>PLAN ASISTENSI:</b> Plan de salud para emergencias médicas y atención
				médica inmediata.
			</p>

			<div className="font-bold ml-4">1. Afiliación</div>
			<p className="my-4">
				Para obtener La Membresía, las personas deberán crear su cuenta en la
				página web{' '}
				<a
					href="https://www.asistensi.com"
					className="font-bold cursor-pointer"
				>
					www.asistensi.com
				</a>{' '}
				o en la App de ASISTENSI y aceptar los presentes términos y condiciones.
			</p>
			<p className="my-4">
				De igual manera podrán afiliar a sus familiares, para lo cual deberán
				contar con el consentimiento previo de los mismos. Al afiliar a
				familiares y aceptar estos términos y condiciones, el miembro declara
				que cuenta con el consentimiento y autorización de los familiares
				afiliados.
			</p>
			<p className="my-4">
				En caso de menores de edad, sólo podrán inscribirlos las personas que
				tengan la patria potestad de los menores.
			</p>

			<div className="font-bold ml-4">2. Condición de Miembro </div>
			<div className="my-4">
				Para obtener la Membresía, se deben cumplir las siguientes condiciones:
				<ul className="mt-2">
					<li className="list-disc ml-8">Ser Persona Física;</li>
					<li className="list-disc ml-8">
						Tener familiares residentes en México, República Dominicana y/o
						Venezuela;
					</li>
					<li className="list-disc ml-8">Conocimiento del Español;</li>
					<li className="list-disc ml-8">
						Estar interesado en la salud y bienestar;
					</li>
					<li className="list-disc ml-8">
						Tener un teléfono celular y una cuenta de correo electrónico activo;
					</li>
					<li className="list-disc ml-8">
						La persona física no puede estar en ninguna lista de sanciones OFAC
						(Office of Foreign Assets Control), UE (Unión Europea), UAF (Unidad
						de Análisis Financiero) y demás internacionales y debe cumplir con
						la normativa internacional de Prevención de Blanqueo de Capitales;
					</li>
					<li className="list-disc ml-8">Ser mayor de edad; y</li>
					<li className="list-disc ml-8">
						Asistensi se reserva el derecho de actualizar y ampliar los
						criterios de elegibilidad de acuerdo con el artículo 2 de este
						documento de Términos y Condiciones.
					</li>
				</ul>
			</div>

			<div className="font-bold ml-4">3. Beneficios </div>
			<div className="my-4">
				Los Miembros de Asistensi tendrán acceso a los siguientes beneficios:
				<ul className="mt-2">
					<li className="list-disc ml-8">
						Acceso a la plataforma digital y App Asistensi;
					</li>
					<li className="list-disc ml-8">
						Posibilidad de contratar los planes Asistensi;
					</li>
					{/* <li className="list-disc ml-8">
            Acceso al boletín informativo de Asistensi con información valiosa y de actualidad sobre salud y bienestar, entre otros;
          </li> */}
					<li className="list-disc ml-8">
						Telemedicina digital ilimitada (sin límite de uso) a través del
						portal ASISTENSI SOS;
					</li>
					<li className="list-disc ml-8">
						Descuentos o promociones exclusivas con terceros con los que
						Asistensi ha logrado condiciones especiales para sus miembros; y
					</li>
					<li className="list-disc ml-8">
						Cualquier otro beneficio que Asistensi decida incluir para sus
						miembros, siempre de manera optativa para estos.
					</li>
				</ul>
			</div>

			<div className="font-bold ml-4">
				4. Inscripción y Renovación Inscripción:{' '}
			</div>
			<p className="my-4">
				Se considera obligatorio para el visitante completar el formulario de
				registro en todos sus campos con datos válidos para convertirse en
				Miembro del Colectivo Asistensi, poder acceder a los beneficios y para
				la adquisición de servicios ofrecidos en la página web.
			</p>
			<p className="my-4">
				La inscripción de la persona física como Miembro de Asistensi será
				gratuita durante el primer año, periodo denominado Periodo de Prueba.{' '}
				<span className="font-bold">
					{' '}
					A partir del segundo año, la renovación tendrá un coste de un dólar de
					los Estados Unidos de América, (USD 1.00)
				</span>{' '}
				y cuyo pago se realizará a través de una plataforma de pago seguro.
				Dicho pago se realizará mediante previa notificación por parte de
				Asistensi y el Miembro tendrá derecho a la cancelación de la Membresía
				en dado caso que no quiera realizar el pago al momento en que tenga que
				realizar el mencionado pago.
			</p>
			<p className="my-4">
				La inscripción supondrá la oportunidad de una entrada a los beneficios
				exclusivos del Colectivo Asistensi descritos en la Cláusula 3, que
				podrán disfrutar tanto el Miembro como sus familiares.
			</p>

			<div className="font-bold ml-4">
				5. Pérdida de la condición de Miembro{' '}
			</div>
			<div className="my-4">
				Las personas que se inscriban en la página web{' '}
				<a
					href="https://www.asistensi.com"
					className="font-bold cursor-pointer"
				>
					www.asistensi.com
				</a>{' '}
				podrán perder su condición de Miembros por las siguientes razones:
				<ul className="mt-2">
					<li className="list-disc ml-8">
						Manifestación expresa de la voluntad del usuario de dejar de ser
						Miembro;
					</li>
					<li className="list-disc ml-8">
						Rechazo por parte de Asistensi por el mal uso de la plataforma
						mediante mala fe afectando negativamente al grupo Colectivo de
						Asistensi; ó
					</li>
					<li className="list-disc ml-8">
						Decisión unilateral de Asistensi de retirar la condición de Miembro
						a cualquier usuario por no cumplir con los requisitos o principios
						de la Membresía.
					</li>
				</ul>
			</div>
			<div className="font-bold ml-4">6. Transferibilidad</div>
			<p className="my-4">La Membresía es personal e intransferible.</p>

			<div className="font-bold ml-4">7. Consultas, Comentarios y Quejas</div>
			<p className="my-4">
				Nuestro objetivo es responder a cualquier consulta, comentario o queja
				recibida en un máximo de cinco (5) días laborables. Esta respuesta podrá
				ser una confirmación de que hemos recibido su consulta o queja, mientras
				llevamos a cabo investigaciones adicionales.
			</p>
			<div className="my-4">
				Puede ponerse en contacto con nosotros a través de:
				<ul className="mt-2">
					<li className="list-disc ml-8">
						correo electrónico:{' '}
						<a href="mailto:info@asistensi.com" className="font-bold">
							info@asistensi.com
						</a>
					</li>
					<li className="list-disc ml-8">
						teléfono:{' '}
						<a href="tel:13054558811" className="font-bold">
							1 (305) 455 88 11
						</a>{' '}
						de lunes a viernes de 9:00 a 17:00 (UTC)
					</li>
				</ul>
			</div>

			<div className="font-bold ml-4">8. Variación</div>
			<p className="mt-4 mb-4">
				Los Términos y Condiciones podrán modificarse en cualquier momento,
				notificándolo por escrito a los Miembros del Colectivo. La edición más
				reciente de este documento de Términos y Condiciones será vinculante
				para todos los Miembros.
			</p>
		</div>
	);
};

export default MemberTerms;
